input, textarea, select {
	background-color: #2f2f2f;
	color: white;
	border: none;
	border-radius: 0.15rem;
	padding: 3px;
}

button:not(.default) {
	min-width: 5rem;
	border: none;
	border-radius: 0.25rem;
	background-color: var(--xr-dark);

	padding: 0.25rem 0.5rem;

	color: white;
	cursor: pointer;
}

input:disabled,textarea:disabled,button:disabled,select:disabled
{
	opacity: 0.4;
	pointer-events: none;
}

button:disabled {
	background-color: var(--gray1);
	/*color: #ccc;*/
	pointer-events: none;
	cursor: not-allowed;
}

::-webkit-scrollbar
{
	width: 7px;
	height: 7px;
	background: var(--dark1);
}

::-webkit-scrollbar-thumb
{
	background: var(--gray2);
	border-radius: 5px;
}
