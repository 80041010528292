@import "defaults.css";

:root {
    --gray0: #f2f2f2;
    --gray1: #808080;
    --gray2: #777777;

    --dark0: #333333;
    --dark1: #262626;
    --dark2: #1a1a1a;

    --xr-dark: #02a59b;
    --xr-light: #5ad2d2;

    --gray0-alt: #4d4d4d;
    --gray1-alt: #3f3f3f;
    --gray2-alt: #333333;
    --red0: #d04b4c;

    --red-custon: #cf4b4c;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Segoe UI Semibold", serif;

    color: white;
}

body {
    background-color: var(--dark0);
}

img.tint-xr{
    filter: invert(47%) sepia(18%) saturate(6013%) hue-rotate(145deg) brightness(92%) contrast(98%);
}

img.tint-xr--dark {
    filter: invert(91%) sepia(11%) saturate(2481%) hue-rotate(133deg) brightness(91%) contrast(80%);
}

.max {
    width: 100%;
    height: 100%;
}

.clamp-max{
    max-width: 100%;
    max-height: 100%;
}

.c-gray0    {      color: var(--gray0);         }
.c-gray1    {      color: var(--gray1);         }
.c-gray2    {      color: var(--gray2);         }
.c-dark0    {      color: var(--dark0);         }
.c-dark1    {      color: var(--dark1);         }
.c-dark2    {      color: var(--dark2);         }
.c-xr       {      color: var(--xr-light);      }
.c-xr--dark  {      color: var(--xr-dark);       }

.t-right  { text-align: right;  }
.t-center { text-align: center; }

.card .f-big     {   font-size: 2em;     }
.card .f-normal  {   font-size: 1.25em;  }
.card .f-small   {   font-size: 0.75em;  }

svg.fill-red    * {  fill: red;              }
svg.fill-green  * {  fill: #02a42d;          }
svg.fill-xr     * {  fill: var(--xr-light);  }

.pointer { cursor: pointer; }